import React from "react";
import createTheme from "@mui/material/styles/createTheme";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Box from "@mui/material/Box";
import TabsComponent from "./components/TabsComponent";

export default function App() {
    const theme = createTheme({
        palette: {
            contrastThreshold: 2,
            primary: {
                main: "#8F91E1",
            },
            secondary: {
                main: "#FFFFFF",
            }
        },
    });

    return <ThemeProvider theme={theme}>
        <Box component='main'
             fontWeight='fontWeightMedium'
             sx={{
                 marginTop: 0,
                 display: 'flex',
                 flexDirection: 'column',
                 alignItems: 'center',
                 maxWidth: "auto",
                 bgcolor: `rgba(0, 0, 0, 0)`,
             }}>
            <TabsComponent/>
        </Box>
    </ThemeProvider>
}
