import DestinationsPresenter from "../presenters/DestinationsPresenter";
import React from "react";
import {DataGrid, esES} from "@mui/x-data-grid";
import GetDestinationsAPIConnector from "../connectors/GetDestinationsAPIConnector";
import createTheme from "@mui/material/styles/createTheme";
import Box from "@mui/material/Box";

interface SipriDataGridProps {
    community: string,
    fromTown: string,
    townsArray: string[],
}

export default class SipriDataGrid extends React.Component <any, any> {

    constructor(props: SipriDataGridProps) {
        super(props);
        this.state = {
            community: this.props.community,
            fromTown: this.props.fromTown,
            townsArray: this.props.townsArray,
            theme: createTheme(),
            rows: [{id: 0, from: '', to: 'Cargando...', province: '', distance: '', time: ''}],
            columns: [
                {field: 'id', headerName: '#', flex: 0.15, maxWidth: 50},
                {
                    field: 'to',
                    headerName: 'Destino',
                    editable: false,
                    minWidth: 150,
                    flex: 1,
                },
                {
                    field: 'time',
                    headerName: 'Tiempo',
                    editable: false,
                    minWidth: 75,
                    flex: 0.65,
                },
                {
                    field: 'distance',
                    headerName: 'Distancia',
                    editable: false,
                    minWidth: 75,
                    flex: 0.65
                },
                {
                    field: 'province',
                    headerName: 'Provincia',
                    editable: false,
                    minWidth: 100,
                    flex: 0.85,
                },
                {
                    field: 'population',
                    headerName: 'Habitantes',
                    editable: false,
                    minWidth: 100,
                    flex: 0.85,
                },
            ]
        };
    }

  componentDidMount() {
    this._renderDestinationsDataGrid()
  }

  _renderDestinationsDataGrid() {

    const connector = new GetDestinationsAPIConnector();
    const presenter = new DestinationsPresenter();

    connector.getSipriDestinations(this.state.community, this.state.fromTown, this.state.townsArray)
        .then((response) => {
            if (response.length < 1) {
                this.setState({rows: [{id: 0, from: '', to: 'No hay datos. Prueba con otra localidad de origen más grande.', province: '', distance: '', time: ''}]});
                return;
            }
            const rows = presenter.getDestinationsData(response);
            this.setState({rows: rows});
        });
  }

  render() {
      return (
          <Box style={{height: 500, width: '100%'}}>
              <DataGrid
                  rows={this.state.rows}
                  columns={this.state.columns}
                  initialState={{
                      pagination: { paginationModel: { pageSize: 50 } },
                  }}
                  pageSizeOptions={[50]}
                  localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                  getRowHeight={() => 'auto'}
              />
          </Box>
      )
  }
}
