import React from 'react';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import SearchDestinationForm from "./SearchDestinationForm";
import SipriForm from "./SipriForm";


export default function TabsComponent() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box
            sx={{
                bgcolor: `rgba(0, 0, 0, 0)`,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop: 2,
                maxWidth: 1000,
                width: 1,
            }}
        >
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    sx={{
                        fontWeight: 700,
                    }}
                >
                    <Tab sx={{
                        fontWeight: 700,
                    }} label="Modo General"/>
                    <Tab sx={{
                        fontWeight: 700,
                    }} label="Modo Suplentes"/>
                </Tabs>
            </AppBar>
            {value === 0 && (
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 1,
                        p: 1.5,
                    }}
                >
                    <SearchDestinationForm/>
                </Box>
            )}
            {value === 1 && (
                <Box
                    sx={{
                        marginTop: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: 1,
                        p: 1.5,
                    }}
                >
                    <SipriForm/>
                </Box>
            )}
        </Box>
    );
}
