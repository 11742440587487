// noinspection JSNonASCIINames

export const SPECIALTIES_ANDALUCIA = ["Todos", "Ed. Infantil", "Ed. Primaria", "ESO", "Bachillerato", "F.P. Básica", "F.P. Grado Medio o Superior", "Enseñanzas de Idiomas", "Cursos de Especialización de F.P. Grado Medio", "Cursos de Especialización de F.P. Grado Superior", "Ed. Especial", "Enseñanzas de Música", "Enseñanzas de Música no Regladas", "Régimen general", "Régimen de Personas Adultas", "Régimen especial", "C.F. de Grado Medio de Artes Plásticas y Diseño", "C.F. de Grado Superior de Artes Plásticas y Diseño", "Enseñanzas de Idiomas (Matrícula libre)", "Enseñanzas Deportivas de Grado Medio (sin Ens. Deportivas periodo transitorio)", "Ed. de Adultos Formal", "Bachillerato para Personas Adultas", "Bachillerato Semipresencial/Distacia"]
export const SPECIALTIES = ["Todos", "Ed. Infantil", "Ed. Primaria", "ESO", "Bachillerato", "F.P. Básica", "F.P. Grado Medio o Superior", "Enseñanzas de Idiomas", "Cursos de Especialización de F.P. Grado Medio", "Cursos de Especialización de F.P. Grado Superior", "Enseñanzas de Música", "Enseñanzas de Música no Regladas", "Régimen general", "Régimen de Personas Adultas", "Régimen especial", "C.F. de Grado Medio de Artes Plásticas y Diseño", "C.F. de Grado Superior de Artes Plásticas y Diseño", "Enseñanzas de Idiomas (Matrícula libre)", "Enseñanzas Deportivas de Grado Medio (sin Ens. Deportivas periodo transitorio)", "Ed. de Adultos Formal", "Bachillerato para Personas Adultas", "Bachillerato Semipresencial/Distacia"]

export const COMMUNITIES = ['Andalucía', 'Aragón', 'Principado de Asturias', 'Cantabria', 'Castilla y León', 'Castilla-La Mancha', 'Cataluña/Catalunya', 'Comunitat Valenciana', 'Extremadura', 'Galicia', 'Comunidad de Madrid', 'Región de Murcia', 'Navarra', 'País Vasco/Euskadi', 'La Rioja'];

export const CICLOS_ANDALUCIA = [{
    "id": "07305G",
    "label": "07305G - Animaciones 3D, Juegos y Entornos Interactivos"
}, {"id": "14301G", "label": "14301G - Administración de sistemas informáticos en red"}, {
    "id": "08205G",
    "label": "08205G - Construcción"
}, {"id": "06301G", "label": "06301G - Comercio Internacional"}, {
    "id": "10301G",
    "label": "10301G - Diseño en fabricación mecánica"
}, {"id": "12201G", "label": "12201G - Peluquería y Cosmetica Capilar"}, {
    "id": "11305G",
    "label": "11305G - Animación Sociocultural y Turística"
}, {"id": "24302G", "label": "24302G - Química y Salud Ambiental"}, {
    "id": "12302G",
    "label": "12302G - Estética Integral y Bienestar"
}, {"id": "01302G", "label": "01302G - Gestión Forestal y del Medio Natural"}, {
    "id": "02301G",
    "label": "02301G - Enseñanza y Animación Sociodeportiva"
}, {"id": "18204G", "label": "18204G - Planta Química"}, {
    "id": "07302G",
    "label": "07302G - Iluminación, Captación y Tratamiento de Imagen"
}, {"id": "27201G", "label": "27201G - Piedra Natural"}, {
    "id": "04301G",
    "label": "04301G - Asistencia a la Dirección"
}, {"id": "15301G", "label": "15301G - Diseño y Amueblamiento"}, {
    "id": "24201G",
    "label": "24201G - Emergencias y Protección Civil"
}, {"id": "23201G", "label": "23201G - Redes y Estaciones de Tratamiento de Aguas"}, {
    "id": "12304G",
    "label": "12304G - Caracterización y Maquillaje Profesional"
}, {"id": "16201G", "label": "16201G - Electromecánica de vehículos automóviles"}, {
    "id": "13201G",
    "label": "13201G - Panadería, repostería y confitería"
}, {"id": "19203G", "label": "19203G - Emergencias sanitarias"}, {
    "id": "13203G",
    "label": "13203G - Aceite de Oliva y Vinos"
}, {"id": "19309G", "label": "19309G - Radioterapia y Dosimetria"}, {
    "id": "19202G",
    "label": "19202G - Farmacia y parafarmacia"
}, {"id": "19306G", "label": "19306G - Prótesis Dentales"}, {
    "id": "16307G",
    "label": "16307G - Formación para la Movilidad Segura y Sostenible"
}, {"id": "19201G", "label": "19201G - Cuidados auxiliares de enfermería"}, {
    "id": "09304G",
    "label": "09304G - Sistemas de Telecomunicaciones e Informáticos"
}, {"id": "17203G", "label": "17203G - Instalaciones de producción de calor"}, {
    "id": "10201G",
    "label": "10201G - Mecanizado"
}, {"id": "14303G", "label": "14303G - Desarrollo de aplicaciones web"}, {
    "id": "19201A",
    "label": "19201A - Cuidados auxiliares de enfermería(Personas Adultas)"
}, {"id": "15202G", "label": "15202G - Carpintería y mueble"}, {
    "id": "17303G",
    "label": "17303G - Mantenimiento de Instalaciones Térmicas y de Fluidos"
}, {"id": "05201G", "label": "05201G - Preimpresión Digital"}, {
    "id": "08303G",
    "label": "08303G - Proyectos de edificación"
}, {"id": "03203G", "label": "03203G - Cultivos acuícolas"}, {
    "id": "20301G",
    "label": "20301G - Mediación Comunicativa"
}, {"id": "01303G", "label": "01303G - Paisajismo y Medio Rural"}, {
    "id": "07301G",
    "label": "07301G - Sonido para Audiovisuales y Espectáculos"
}, {"id": "06203G", "label": "06203G - Comercialización de Productos Alimentarios"}, {
    "id": "08301G",
    "label": "08301G - Proyectos de Obra Civil"
}, {"id": "01201G", "label": "01201G - Producción agropecuaria"}, {
    "id": "07303G",
    "label": "07303G - Realización de Proyectos de Audiovisuales y Espectáculos"
}, {"id": "09303G", "label": "09303G - Automatización y Robótica Industrial"}, {
    "id": "14302G",
    "label": "14302G - Desarrollo de aplicaciones multiplataforma"
}, {"id": "20201G", "label": "20201G - Atención a Personas en Situacion de Dependencia"}, {
    "id": "16301G",
    "label": "16301G - Automoción"
}, {"id": "03201G", "label": "03201G - Navegación y Pesca de Litoral"}, {
    "id": "05202G",
    "label": "05202G - Impresión Gráfica"
}, {"id": "20305G", "label": "20305G - Promoción de Igualdad de Género"}, {
    "id": "03303G",
    "label": "03303G - Acuicultura"
}, {"id": "19312G", "label": "19312G - Electromedicina Clínica"}, {
    "id": "06305G",
    "label": "06305G - Marketing y Publicidad"
}, {"id": "21304G", "label": "21304G - Patronaje y Moda"}, {
    "id": "01205G",
    "label": "01205G - Jardinería y floristería"
}, {"id": "24303G", "label": "24303G - Educación y Control Ambiental"}, {
    "id": "13302G",
    "label": "13302G - Vitivinicultura"
}, {"id": "12301G", "label": "12301G - Asesoría de Imagen Personal y Corporativa"}, {
    "id": "14201G",
    "label": "14201G - Sistemas microinformáticos y redes"
}, {
    "id": "18306G",
    "label": "18306G - Fabricación de Productos Farmacéuticos, Biotecnológicos y Afines"
}, {"id": "01206G", "label": "01206G - Aprovechamiento y Conservación del Medio Natural"}, {
    "id": "19302G",
    "label": "19302G - Higiene Bucodental"
}, {"id": "06201G", "label": "06201G - Actividades Comerciales"}, {
    "id": "13301G",
    "label": "13301G - Procesos y calidad en la industria alimentaria"
}, {"id": "15201G", "label": "15201G - Instalación y Amueblamiento"}, {
    "id": "02202G",
    "label": "02202G - Actividades Ecuestres"
}, {"id": "19307G", "label": "19307G - Ortoprótesis y Producto de Apoyo"}, {
    "id": "11201G",
    "label": "11201G - Servicios en restauración"
}, {"id": "13206G", "label": "13206G - Elaboración de productos alimenticios"}, {
    "id": "19301G",
    "label": "19301G - Dietética"
}, {"id": "01202G", "label": "01202G - Producción agroecológica"}, {
    "id": "08202G",
    "label": "08202G - Obras de Interior, Decoración y Rehabilitacion"
}, {"id": "10304G", "label": "10304G - Construcciones Metálicas"}, {
    "id": "19310G",
    "label": "19310G - Imagen para el Diagnóstico y Medicina Nuclear"
}, {"id": "07202G", "label": "07202G - Video, Disc-Jockey y Sonido"}, {
    "id": "02203G",
    "label": "02203G - Guía en el Medio Natural y de Tiempo Libre."
}, {
    "id": "16206G",
    "label": "16206G - Montaje de Estructuras e Instalación de Sistemas Aeronáuticos."
}, {
    "id": "03302G",
    "label": "03302G - Organización y Mantenimiento de Maquinarias de Buques y Embarcaciones"
}, {"id": "19311G", "label": "19311G - Audiología protésica"}, {
    "id": "21303G",
    "label": "21303G - Vestuario a Medida y de Espectáculos"
}, {"id": "23303G", "label": "23303G - Gestión del Agua"}, {
    "id": "09202G",
    "label": "09202G - Instalaciones Eléctricas y Automáticas"
}, {
    "id": "17301G",
    "label": "17301G - Desarrollo de Proyectos de Instalaciones Térmicas y de Fluidos"
}, {"id": "08302G", "label": "08302G - Organización y Control de Obras de Construcción"}, {
    "id": "05302G",
    "label": "05302G - Diseño y Gestión de la Producción Gráfica"
}, {"id": "11304G", "label": "11304G - Dirección de servicios de restauración"}, {
    "id": "01304G",
    "label": "01304G - Ganaderia y Asistencia en Sanidad Animal"
}, {"id": "19303G", "label": "19303G - Anatomía Patológica y Citodiagnóstico"}, {
    "id": "03301G",
    "label": "03301G - Transporte Marítimo y Pesca de Altura"
}, {"id": "17202G", "label": "17202G - Instalaciones frigoríficas y de climatización"}, {
    "id": "03204G",
    "label": "03204G - Operaciones Subacuáticas e Hiperbaricas"
}, {"id": "27202G", "label": "27202G - Excavaciones y Sondeos"}, {
    "id": "11302G",
    "label": "11302G - Gestión de alojamientos turísticos"
}, {"id": "05301G", "label": "05301G - Diseño y Edición de Publicaciones Impresas y Multimedia"}, {
    "id": "12303G",
    "label": "12303G - Estilismo y Dirección de Peluquería"
}, {"id": "20303G", "label": "20303G - Integración Social"}, {
    "id": "07304G",
    "label": "07304G - Producción de Audiovisuales y Espectáculos"
}, {"id": "12202G", "label": "12202G - Estética y Belleza"}, {
    "id": "17201G",
    "label": "17201G - Mantenimiento Electromecánico"
}, {"id": "04302G", "label": "04302G - Administración y Finanzas"}, {
    "id": "17302G",
    "label": "17302G - Mecatrónica Industrial"
}, {"id": "09302G", "label": "09302G - Sistemas electrotécnicos y automatizados"}, {
    "id": "10305G",
    "label": "10305G - Óptica de anteojería"
}, {"id": "11303G", "label": "11303G - Guía, información y asistencias turísticas"}, {
    "id": "18201G",
    "label": "18201G - Operaciones de Laboratorio"
}, {"id": "09301G", "label": "09301G - Mantenimiento Electrónico"}, {
    "id": "19304G",
    "label": "19304G - Laboratorio Clínico y Biomédico"
}, {"id": "16202G", "label": "16202G - Carrocería"}, {
    "id": "11202G",
    "label": "11202G - Cocina y Gastronomía"
}, {"id": "23301G", "label": "23301G - Eficiencia Energética y Energía Solar Térmica"}, {
    "id": "16204G",
    "label": "16204G - Electromecánica de Maquinaria"
}, {"id": "24301G", "label": "24301G - Coordinacion de Emergencias y Protección Civil"}, {
    "id": "03202G",
    "label": "03202G - Mantenimiento y Control de la Maquinaria de Buques y Embarcaciones"
}, {
    "id": "10303G",
    "label": "10303G - Programación de la Producción en Moldeo de Metales y Polímeros"
}, {"id": "12305G", "label": "12305G - Termalismo y Bienestar"}, {
    "id": "11301G",
    "label": "11301G - Agencias de viajes y gestión de eventos"
}, {"id": "10205G", "label": "10205G - Conformado por Moldeo de Metales y Polímeros"}, {
    "id": "09203G",
    "label": "09203G - Instalaciones de telecomunicaciones"
}, {"id": "18303G", "label": "18303G - Laboratorio de análisis y de control de calidad"}, {
    "id": "10204G",
    "label": "10204G - Soldadura y calderería"
}, {"id": "16203G", "label": "16203G - Mantenimiento de Material Rodante Ferroviario"}, {
    "id": "06302G",
    "label": "06302G - Transporte y Logística"
}, {"id": "02302G", "label": "02302G - Acondicionamiento Físico"}, {
    "id": "18305G",
    "label": "18305G - Química industrial"
}, {"id": "06303G", "label": "06303G - Gestión de Ventas y Espacios Comerciales"}, {
    "id": "20302G",
    "label": "20302G - Educación infantil"
}, {"id": "10302G", "label": "10302G - Programación de la Producción en Fabricación Mecánica"}, {
    "id": "11306G",
    "label": "11306G - Dirección de cocina"
}, {"id": "21201G", "label": "21201G - Confección y Moda"}, {
    "id": "23302G",
    "label": "23302G - Energías Renovables"
}, {"id": "19308G", "label": "19308G - Documentación y Administración Sanitaria"}, {
    "id": "21202G",
    "label": "21202G - Calzado y Complementos de Moda"
}, {"id": "04201G", "label": "04201G - Gestión administrativa"}, {
    "id": "17304G",
    "label": "17304G - Prevención de riesgos profesionales"
}]
