import DestinationEntity from '../entities/DestinationEntity';
import GetDestinationsConnectorInterface from "../interfaces/connectors/GetDestinationsConnectorInterface";

export default class GetDestinationsAPIConnector extends GetDestinationsConnectorInterface {
    baseUrl: string;

    constructor() {
        super();

        this.baseUrl = 'https://api.midestinodocente.es/';
    }

    getDestinations(community: string, fromTown: string, limit: number, specialty: string, ciclos: string) {
        const resourceUrl = 'travel-destination/';
        const encodedFromTown = fromTown.replaceAll("/", '__');
        const encodedCommunity = community.replaceAll("/", '__');
        let url = new URL(this.baseUrl + resourceUrl + encodedCommunity + "/" + encodedFromTown);

        if(ciclos) {
            const params = {'limit': limit.toString(), "specialty": specialty, "ciclos": ciclos}
            url.search = new URLSearchParams(params).toString()
            return this._doRequest(url);
        }

        const params = {'limit': limit.toString(), "specialty": specialty}
        url.search = new URLSearchParams(params).toString()

        return this._doRequest(url);
    }

    getSipriDestinations(community: string, fromTown: string, townsArray: string[]) {
        const resourceUrl = 'travel-destination/';
        const encodedFromTown = fromTown.replaceAll("/", '__');
        const encodedCommunity = community.replaceAll("/", '__');
        let url = new URL(this.baseUrl + resourceUrl + encodedCommunity + "/" + encodedFromTown + '/sipri');
        const allTowns = townsArray.join(',');
        const params = {'sipri_towns': allTowns}
        url.search = new URLSearchParams(params).toString()

        return this._doRequest(url);
    }

    _doRequest(url: URL) {
        return fetch(url)
            .then((response => response.json()))
            .then((responseData) => {
                const destinations = [];
                let destination;
                const data = responseData.data ? responseData.data[0] : [];

                for (let i = 0; i < data.length; i += 1) {
                    destination = new DestinationEntity(data[i].from, data[i].to, data[i].province,
                        data[i].time, data[i].distance, data[i].population);
                    destinations.push(destination);
                }
                return destinations;
            });
    }

    getTownsFromCommunity(community: string) {
        const encodedCommunity = community.replaceAll("/", '__');
        const resourceUrl = "towns/" + encodedCommunity;
        const url = new URL(this.baseUrl + resourceUrl);
        return fetch(url)
            .then((response => response.json()))
            .then((responseData) => {
                return responseData.data[0];
            })
    }
}
