export default class GetDestinationsConnectorInterface {
    getDestinations (community: string, fromTown: string, limit: number, specialty: string, ciclos: string) {
        throw new Error('Not Implemented');
    }

    getSipriDestinations(community: string, fromTown: string, townsArray: string[]) {
        throw new Error('Not Implemented');
    }

    getTownsFromCommunity(community: string) {
        throw new Error('Not Implemented');
    }
}
