export default class DestinationEntity {
    from: string;
    to: string;
    province: string;
    time: number;
    distance: number;
    population: number;

    constructor(from: string, to: string, province: string, time: number, distance: number, population: number) {
        this.from = from;
        this.to = to;
        this.province = province;
        this.time = time;
        this.distance = distance;
        this.population = population;
    }
}