import React, {Component, SyntheticEvent} from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import SipriDataGrid from "./SipriDataGrid";
import {COMMUNITIES} from "../utils/TownsListsUtils";
import GetDestinationsAPIConnector from "../connectors/GetDestinationsAPIConnector";
import { Widget } from '@typeform/embed-react';

export default class SipriForm extends Component {
    state = {
        community: "Andalucía",
        town: '',
        sipriTowns: [],
        shouldRenderForm: true,
        shouldOpenSnackbar: false,
        allTowns: ["Cargando..."],
        isFirstLoad: true,
        isLoading: true,
        shouldShowTypeform: false,
    };
    connector = new GetDestinationsAPIConnector();

    componentDidMount() {
        if (this.state.isFirstLoad) {
            this.connector.getTownsFromCommunity(this.state.community)
            .then((response) => {
                this.setState({allTowns: response, isFirstLoad: false, isLoading: false});
            });
        }
    }

    _handleCommunityChange = (event: SyntheticEvent, value: string | null) => {
        this.setState({community: value, isLoading: true});
        const community = value ? value : "Andalucía";
        this.connector.getTownsFromCommunity(community)
            .then((response) => {
                this.setState({allTowns: response, isLoading: false});
            });
    };

    _handleTownChange = (event: SyntheticEvent, value: string | null) => {
        this.setState({town: value})
    };

    handleShowTypeForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        this.setState({shouldShowTypeform: true})
    };

    handleSubmit = () => {
        this.setState({shouldShowTypeform: false});
        if (this.state.town !== '') {
            this.setState({shouldRenderForm: false});
        } else {
            this.setState({shouldOpenSnackbar: true});
        }
    };

    _handleBack = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({shouldRenderForm: true});
    };

    _renderCommunityInput() {
        return (
            <Autocomplete
                options={COMMUNITIES}
                defaultValue={COMMUNITIES[0]}
                sx={{width: 300}}
                autoHighlight
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="dense"
                        required
                        fullWidth
                        id="community"
                        label="Introduce tu Comunidad Autónoma"
                        size='small'
                        name='community'
                    />
                )}
                onChange={this._handleCommunityChange}
            />
        );
    }

    _renderMultipleTextInput() {
        return (
            <Autocomplete
                multiple
                sx={{width: 300}}
                options={this.state.allTowns}
                filterSelectedOptions
                onChange={(event: React.SyntheticEvent, newInputValues: string[]) => {
                    this.setState({sipriTowns: newInputValues});
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="dense"
                        required
                        fullWidth
                        id="town"
                        label="Introduce los destinos disponibles"
                        size='small'
                        name='town'
                    />
                )}
            />
        )
    }

    _renderTextInput() {
        return (
            <Autocomplete
                options={this.state.allTowns}
                sx={{width: 300}}
                autoHighlight
                renderInput={(params) => (
                    <TextField
                        {...params}
                        margin="dense"
                        required
                        fullWidth
                        id="town"
                        label="Introduce tu localidad"
                        size='small'
                        name='town'
                    />
                )}
                onChange={this._handleTownChange}
            />
        );
    }

    _renderSubmitButton() {
        return (
            <Button
                sx={{mt: 1, fontWeight: 700}}
                type="submit"
                style={{marginLeft: "auto"}}
                variant="contained"
                size="large"
                color="primary"
                fullWidth={true}
            >
                Buscar
            </Button>
        );
    }

    _renderSnackbar() {
        const action = (
            <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                        this.setState({shouldOpenSnackbar: false});
                    }}
                >
                    <CloseIcon fontSize="small"/>
                </IconButton>
            </React.Fragment>
        );

        return (
            <Snackbar
                open={this.state.shouldOpenSnackbar}
                autoHideDuration={5000}
                onClose={() => {
                    this.setState({shouldOpenSnackbar: false});
                }}
                message="Por favor, rellena todos los campos."
                action={action}
            />
        );
    }

    _renderBackButton() {
        return (
            <Button
                style={{marginLeft: "auto", flexGrow: 1}}
                variant="contained"
                size="medium"
                color="secondary"
                onClick={this._handleBack}
            >
                Atrás
            </Button>
        )
    }

    render() {
        if (this.state.shouldShowTypeform) {
            return <Box sx={{height: '70vh', width: '100%'}}>
                <Widget id="a2RpJoqy" onClose={() => this.handleSubmit()} onSubmit={() => this.handleSubmit()} style={{width: '100%', height: '100%'}} hidden={{comunidadautonoma: this.state.community, localidad: this.state.town, tipodecentro: 'Desconocido'}} />
            </Box>
        }
        if (this.state.shouldRenderForm) {
            return (
                <>
                    <Typography component={'span'} variant="h5">
                        Introduce los destinos de tu convocatoria y los ordenamos por ti
                    </Typography>
                    <Box component="form" onSubmit={this.handleShowTypeForm} noValidate sx={{mt: 1}}>
                        {this._renderCommunityInput()}
                        {this._renderMultipleTextInput()}
                        {this._renderTextInput()}
                        {this._renderSubmitButton()}
                        {this._renderSnackbar()}
                    </Box>
                </>
            );
        } else {
            return (
                <>
                    <Typography component="h1" variant="h5">
                        Estos son los mejores destinos si vives en {this.state.town}:
                    </Typography>
                    <SipriDataGrid community={this.state.community} fromTown={this.state.town}
                                   townsArray={this.state.sipriTowns}/>
                    {this._renderBackButton()}
                </>
            );
        }
    }

}
